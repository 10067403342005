import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Giftcard from './Giftcard';
import * as serviceWorker from './serviceWorker';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import moment from 'moment';

class Root extends Component {

  render() {
    return (

      <div className="App">
        <header id="header" className="align-items-center">
          <div id="header-top-bar">
            <div className="container">
              <div className="row">
                <div id="top-bar-left">
                  <a id="header-my-link" href="https://giftr.my" />
                  <a id="header-sg-link" href="https://giftr.sg" />
                </div>
                <div id="top-bar-right" >
                  e.giftcard.my powered by Giftr
                </div>
              </div>
            </div>
          </div>
          <div className="container align-items-center">
            <a href="https://giftr.my" target="_blank">
              <img src='/assets/img/giftr_logo.png'/>
            </a>
          </div>
          <hr id="header-hr" />
        </header>

        <main id="main">

          <Router>
            <HttpsRedirect>
              <Switch>
                <Route path="/:shortcode" component={Giftcard}  />
                <Route path="/" component={App}  />
              </Switch>
            </HttpsRedirect>
          </Router>

          <hr id="contact-hr" />
          <section id="contact" className="contact">
            <div className="container">
              <div className="row">


                <div className="col-md-6 col-xs-12 mb-4 col-lg-3">
                  <div className="footer-block">
                    <a href="https://giftr.my"><img className="logo" src="/assets/img/giftr_logo.png" alt="{{ shop.name | escape }}" /></a>
                    <div className="sub-description">Giftr is an online marketplace for gifts connecting people around the world to sell and buy gift related products. We aim to be the go-to destination for gift seekers to solve their gifting needs.</div>
                  </div>
                </div>


                <div className="col-xs-12 col-md-6 mb-4 col-lg-3">
                  <div className="footer-block">

                    <h4>Who We Are</h4>

                    <ul className="list-links list-unstyled">
                      <li><a href="https://giftr.my/pages/about-us" target="_blank">About Us</a></li>
                      <li><a href="https://giftr.my/pages/sell-with-giftr" target="_blank">Join Us As Seller</a></li>
                      <li><a href="https://giftr.my/pages/privacy-policy" target="_blank">Privary Policy</a></li>
                      <li><a href="https://giftr.my/pages/cookies-policy" target="_blank">Cookies Policy</a></li>
                      <li><a href="https://giftr.my/pages/terms" target="_blank">Terms</a></li>
                    </ul>

                  </div>
                </div>


                <div className="col-xs-12 col-md-6 mb-4 col-lg-3">
                  <div className="footer-block">

                    <h4>Follow Us</h4>

                    <ul className="list-links list-unstyled">
                      <li><a href="https://fb.com/giftrmy" target="_blank">
                        <img src="https://cdn.shopify.com/s/files/1/1428/2106/files/facebook_icon_greyscale.png?v=1616405833" />
                        Facebook</a></li>
                      <li><a href="https://www.instagram.com/giftrmy/" target="_blank">
                        <img src="https://cdn.shopify.com/s/files/1/1428/2106/files/instagram_icon_greyscale.png?v=1616405833" />
                        Instagram</a></li>
                    </ul>

                  </div>
                </div>


                <div className="col-lg-3 col-md-6 col-xs-12 mb-4">
                  <div className="footer-block">

                    <h4>Contact Us</h4>

                    <ul className="list-links list-unstyled">
                      <li className="a-info"><i className="fa fa-map-marker"></i><span>Unit 2-1, Level 2, The Podium, Tower 3, UOA Business Park, No.1, Jalan Pengaturcara U1/51A, Seksyen U1, 40150 Shah Alam, Selangor.</span></li>
                      <li className="a-info"><i className="fa fa-envelope"></i><span>support@giftr.my</span></li>
                      <li className="a-info"><i className="fa fa-phone"></i><span>+6019 359 6194 (Call Only. Not available for WhatsApp)</span></li>
                    </ul>

                    <div className="footer-country-links">
                      <a href="https://giftr.my"><img className="footer-country-link" src="//cdn.shopify.com/s/files/1/1428/2106/files/malasya.png?12523006125516354647"/><span>Giftr Malaysia </span></a><br/>
                      <a href="https://giftr.sg/?utm_source=giftr.my&utm_medium=referral&utm_campaign=giftr-main-site&utm_content=footer"><img className="footer-country-link" src="//cdn.shopify.com/s/files/1/1428/2106/files/singapore.png?12523006125516354647"/><span>Giftr Singapore</span></a>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </section>
        </main>

        <footer id="footer">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-lg-6 text-center ">
                <div id="footer-badges">
                  <a target="_blank" href="https://letsencrypt.org/documents/isrg-cps-v4.0/">
                    <img style={{height: '70px'}} src="/assets/img/le-logo-standard.png"/>
                  </a>
                  <div id="___ratingbadge_0">
                    <iframe ng-non-bindable="" frameborder="0" hspace="0" marginheight="0" marginwidth="0" scrolling="no" tabindex="0" vspace="0" width="100%" id="I0_1617444173965" name="I0_1617444173965" src="https://www.google.com/shopping/customerreviews/badge?usegapi=1&amp;merchant_id=116832863&amp;origin=https%3A%2F%2Fgiftr.my&amp;gsrc=3p&amp;ic=1&amp;jsh=m%3B%2F_%2Fscs%2Fapps-static%2F_%2Fjs%2Fk%3Doz.gapi.en.RrjSsKk8Szw.O%2Fam%3DAQ%2Fd%3D1%2Fct%3Dzgms%2Frs%3DAGLTcCPwd_oodKkRT_hYRO1I-9xRcQV4oQ%2Fm%3D__features__#_methods=onPlusOne%2C_ready%2C_close%2C_open%2C_resizeMe%2C_renderstart%2Concircled%2Cdrefresh%2Cerefresh&amp;id=I0_1617444173965&amp;_gfid=I0_1617444173965&amp;parent=https%3A%2F%2Fgiftr.my&amp;pfname=&amp;rpctoken=35596333" data-gapiattached="true" title="Google Customer Reviews"></iframe>
                  </div>
                </div>
                <div className="copyright">
                  &copy; {moment().format('YYYY')} Helpr Asia Bhd (1147034-U). All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
